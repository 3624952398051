import BuilderEditor from '../BuilderEditor';
import BuilderSidebar from '../BuilderSidebar';
import BuilderWrapper from '../BuilderWrapper';

export default function EditDynamicPage({
  entityTitle = 'Page',
  dynamicPage,
  allSitePages,
  categoriesWithTemplates,
  categoriesLinked,
  componentDefinitions,
  endpoints,
  forms,
  dynamicPageModules,
}) {
  return (
    <BuilderWrapper
      {...{
        dynamicPage,
        allSitePages,
        categoriesWithTemplates,
        categoriesLinked,
        componentDefinitions,
        endpoints,
        forms,
        dynamicPageModules,
      }}
    >
      <div className="dp-editor">
        <BuilderSidebar {...{ entityTitle }} />
        <BuilderEditor isEdit />
      </div>
    </BuilderWrapper>
  );
}
