import { useReducer, useEffect, useMemo } from 'react';
import { useDynamicPageCMSDataContext, SitemapContext, SitemapDispatchContext } from './contexts';

import TextInput from '../inputs/form-inputs/TextInput';
import SiteTree from './_builder_sitemap/SiteTree';
import SiteList from './_builder_sitemap/SiteList';
import { transformUrlSlugToTitle } from '../utils/UrlFormatters';

const initialState = {
  isLoading: true,
  isError: false,
  status: '',
  search: '',
  pages: [],
  view: 0,
  hide: {
    categories: false,
    builder: false,
    sitemap: false,
  },
  sort: {
    key: 0,
    order: 0,
  },
  expandedKeys: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    case 'SET_EXPANDED_KEYS':
      return { ...state, expandedKeys: action.payload };
    default:
      return state;
  }
}

const VIEW_OPTIONS = [
  { label: 'Tree', icon: <i className="fe fe-align-right" /> },
  { label: 'List', icon: <i className="fe fe-list" /> },
];

const HIDE_OPTIONS = {
  categories: 'Categories',
  builder: 'Builder',
  sitemap: 'Sitemap',
};

const SORT_OPTIONS = [
  { label: 'Title', value: 0 },
  { label: 'URL', value: 1 },
  { label: 'Date Updated', value: 2 },
];

const SORT_ORDER = [
  { label: 'ASC', value: 0 },
  { label: 'DESC', value: 1 },
];

const filterPages = (pages, keyword) => {
  return pages.filter((page) => page.title.toLowerCase().includes(keyword.toLowerCase()) || page.url_slug.toLowerCase().includes(keyword.toLowerCase()));
};

const createPageMap = (pages) => Object.fromEntries(pages.map((page) => [page.url_slug === '/' ? page.url_slug : page.url_slug.replace(/^\/+/, ''), page]));

const createCategoryMaps = (categoriesWithTemplates) => Object.fromEntries(categoriesWithTemplates.map((cat) => [cat.url_pattern, cat]));

const createTreeNode = (item, fullPath, isCategory, isNested, isPlaceholder) => ({
  ...item,
  title: isPlaceholder ? transformUrlSlugToTitle(fullPath) : item.title,
  isPlaceholder,
  isCategory,
  isNested,
  url_slug: fullPath,
  children: [],
});

const buildTree = (pageMap, categoryMap, pages, withIndex) => {
  const urlsTree = pages.reduce((acc, page) => {
    const urlParts = page.url_slug.replace(/^\/+/, '').split('/').filter(Boolean);

    let currentLevel = acc;
    let fullPath = '';

    urlParts.forEach((part) => {
      fullPath = fullPath ? `${fullPath}/${part}` : part;

      let existingPath = currentLevel.find((level) => level.url_slug === fullPath);

      if (existingPath) {
        currentLevel = existingPath.children;
      } else {
        const urlSlug = urlParts[0];
        const isCategory = !!categoryMap[fullPath];
        const isNested = categoryMap[urlSlug.startsWith('//') ? '/' : urlSlug]?.is_nested;
        const mapItem = pageMap[fullPath];
        const isPlaceholder = !mapItem;

        const newLevel = createTreeNode(mapItem, fullPath, isCategory, isNested, isPlaceholder);

        currentLevel.push(newLevel);
        currentLevel = newLevel.children;
      }
    });
    return acc;
  }, []);

  if (withIndex) {
    const presentIndex = pages.find((page) => page.url_slug === '/');
    urlsTree.unshift({
      id: presentIndex?.id || 'default_home',
      title: presentIndex?.title || 'Index',
      url_slug: '/',
      is_from_sitemap: presentIndex?.is_from_sitemap || true,
      is_dynamic_page: presentIndex?.is_dynamic_page || false,
      children: [],
      ...(pageMap['/'] || {}),
    });
  }

  return urlsTree;
};

const initializeExpandedKeys = (options) => {
  const { pages, parentKey = '', level = 0, expandedKeys = {}, limit = 2 } = options;

  if (level >= limit) return expandedKeys; // Only expand up to limit level

  pages.forEach((page, index) => {
    const key = parentKey ? `${parentKey}-${index}` : `${index}`;
    expandedKeys[key] = true;
    if (page.children.length > 0) {
      initializeExpandedKeys({ pages: page.children, parentKey: key, level: level + 1, expandedKeys, limit });
    }
  });

  return expandedKeys;
};

export default function BuilderSitemap({ className, isSimple }) {
  const { allSitePages, categoriesWithTemplates } = useDynamicPageCMSDataContext();

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSearch = (e) => {
    dispatch({ type: 'UPDATE', payload: { search: e.target.value, isLoading: true } });
  };

  const handleToggleSortKey = (key) => {
    dispatch({ type: 'UPDATE', payload: { isLoading: true } });
    setTimeout(() => {
      dispatch({ type: 'UPDATE', payload: { sort: { ...state.sort, key }, isLoading: false } });
    }, 50);
  };

  const handleToggleSortOrder = (order) => {
    dispatch({ type: 'UPDATE', payload: { isLoading: true } });
    setTimeout(() => {
      dispatch({ type: 'UPDATE', payload: { sort: { ...state.sort, order }, isLoading: false } });
    }, 50);
  };

  const handleToggleHide = (type) => {
    dispatch({ type: 'UPDATE', payload: { isLoading: true } });
    setTimeout(() => {
      dispatch({ type: 'UPDATE', payload: { hide: { ...state.hide, [type]: !state.hide[type] } } });
    }, 50);
  };

  const handleToggleView = () => {
    dispatch({ type: 'UPDATE', payload: { view: state.view === 0 ? 1 : 0, isLoading: true } });
  };

  const pageMap = useMemo(() => createPageMap(allSitePages), [allSitePages]);
  const categoryMap = useMemo(() => createCategoryMaps(categoriesWithTemplates), [categoriesWithTemplates]);

  const memoisedFilteredPages = useMemo(() => {
    const filteredPages = allSitePages.filter((page) => {
      if (state.hide.categories && page.parent_category_id) return false;
      if (state.hide.builder && page.is_dynamic_page) return false;
      if (state.hide.sitemap && page.is_from_sitemap) return false;
      return true;
    });

    const pages = state.search === '' ? filteredPages : filterPages(filteredPages, state.search);

    return pages;
  }, [state.hide.categories, state.hide.builder, state.hide.sitemap, state.search, allSitePages]);

  const memoisedSortedPages = useMemo(() => {
    const sortedPages = [...memoisedFilteredPages].sort((a, b) => {
      const key = state.sort.key;
      const order = state.sort.order;

      switch (key) {
        case 0:
          return order === 0 ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        case 1:
          return order === 0 ? a.url_slug.localeCompare(b.url_slug) : b.url_slug.localeCompare(a.url_slug);
        case 2:
          return order === 0 ? a.updated_at.localeCompare(b.updated_at) : b.updated_at.localeCompare(a.updated_at);
        default:
          return a.title.localeCompare(b.title);
      }
    });

    return sortedPages;
  }, [memoisedFilteredPages, state.sort.key, state.sort.order]);

  const memoisedTree = useMemo(() => {
    return buildTree(
      pageMap,
      categoryMap,
      memoisedFilteredPages.sort((a, b) => a.url_slug.localeCompare(b.url_slug)),
      state.search === '' && !state.hide.sitemap ? true : false
    );
  }, [memoisedFilteredPages, categoriesWithTemplates]);

  const handleSetExpandedKeys = (mode) => {
    dispatch({ type: 'UPDATE', payload: { isLoading: true } });

    // Use setTimeout to create a small delay
    setTimeout(() => {
      switch (mode) {
        case 'collapse':
          dispatch({ type: 'UPDATE', payload: { expandedKeys: {}, isLoading: false } });
          break;
        case 'expand':
          dispatch({ type: 'UPDATE', payload: { expandedKeys: initializeExpandedKeys({ pages: state.pages, limit: 99 }), isLoading: false } });
          break;
        default:
          dispatch({ type: 'UPDATE', payload: { expandedKeys: initializeExpandedKeys({ pages: state.pages }), isLoading: false } });
          break;
      }
    }, 50);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (state.view === 0) {
        dispatch({ type: 'UPDATE', payload: { pages: memoisedTree, isLoading: false } });
        return;
      }

      dispatch({ type: 'UPDATE', payload: { pages: memoisedSortedPages, isLoading: false } });
      return;
    }, 100);

    return () => clearTimeout(handler);
  }, [memoisedTree, memoisedSortedPages, state.view]);

  return (
    <div className={`dp-site-tree ${className}`}>
      <div className="d-flex gap-3">
        <TextInput label="Search" placeholder={`Search all ${allSitePages.length} items by title or URL...`} onChange={handleSearch} />
        {!isSimple && (
          <div className="d-flex gap-3">
            {state.view === 1 && (
              <div className="btn-group" role="group">
                <button type="button" className="btn btn-primary dropdown-toggle dp-button-togglers" data-bs-toggle="dropdown" aria-expanded="false">
                  Sort
                </button>
                <ul className="dropdown-menu">
                  {SORT_OPTIONS.map(({ label, value }) => (
                    <li key={value}>
                      <button type="button" className={`dropdown-item ${state.sort.key === value ? 'active' : ''}`} onClick={() => handleToggleSortKey(value)}>
                        {label}
                      </button>
                    </li>
                  ))}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {SORT_ORDER.map(({ label, value }) => (
                    <li key={value}>
                      <button
                        type="button"
                        className={`dropdown-item ${state.sort.order === value ? 'active' : ''}`}
                        onClick={() => handleToggleSortOrder(value)}
                      >
                        <span>{label}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="btn-group" role="group">
              <button type="button" className="btn btn-primary dropdown-toggle dp-button-togglers" data-bs-toggle="dropdown" aria-expanded="false">
                View
              </button>
              <ul className="dropdown-menu">
                {Object.entries(HIDE_OPTIONS).map(([key, value]) => (
                  <li key={key}>
                    <button type="button" className="dropdown-item d-flex align-items-center gap-4" onClick={() => handleToggleHide(key)}>
                      {state.hide[key] ? <i className="fe fe-eye-off" /> : <i className="fe fe-eye" />} {value}
                    </button>
                  </li>
                ))}
                {state.view === 0 && (
                  <>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button type="button" className="dropdown-item" onClick={() => handleSetExpandedKeys('collapse')}>
                        Collapse All
                      </button>
                    </li>
                    <li>
                      <button type="button" className="dropdown-item" onClick={() => handleSetExpandedKeys('expand')}>
                        Expand All
                      </button>
                    </li>
                    <li>
                      <button type="button" className="dropdown-item" onClick={handleSetExpandedKeys}>
                        Expand 2 levels
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <button type="button" className="btn btn-primary d-flex gap-2" onClick={handleToggleView}>
              {VIEW_OPTIONS[state.view].icon} {VIEW_OPTIONS[state.view].label}
            </button>
          </div>
        )}
      </div>
      <SitemapContext.Provider value={state}>
        <SitemapDispatchContext.Provider value={dispatch}>
          {state.isLoading ? (
            <div className="dp-site-tree-list loading">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {state.view === 0 && <SiteTree isSimple={isSimple} />}
              {state.view === 1 && <SiteList isSimple={isSimple} />}
            </>
          )}
        </SitemapDispatchContext.Provider>
      </SitemapContext.Provider>
    </div>
  );
}
