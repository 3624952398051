const BAD_THRESHOLD = 4.0
const OK_THRESHOLD = 8.0

const BAD_NPS_THRESHOLD = 0
const OK_NPS_THRESHOLD = 40

export function badgeFromScore(score, nps = false){
  if(score == '-') return 'bg-secondary-soft';

  if(nps) return npsBadgeFromScore(score);


  if(score < BAD_THRESHOLD){
    return 'bg-danger-soft';
  } else if(score < OK_THRESHOLD){
    return 'bg-warning-soft';
  }

  return 'bg-success-soft';
}

function npsBadgeFromScore(score){
  if(score < BAD_NPS_THRESHOLD){
    return 'bg-danger-soft';
  } else if(score < OK_NPS_THRESHOLD){
    return 'bg-warning-soft';
  }

  return 'bg-success-soft';
}

export function surveyStateBadge(state){
  switch(state){
    case 'closed':
      return 'bg-info-soft';
    case 'live':
      return 'bg-success-soft';
  }
}
