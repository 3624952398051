import { useDynamicPageCMSDataContext, useSitemapContext, useSitemapDispatchContext } from '../../contexts';
import { removeParentSlug } from '../../../utils/UrlFormatters';

import TreeLeaves from './TreeLeaves';
import StatusBadge from './StatusBadge';
import RowOptions from './RowOptions';

export default function TreeNode({ page, index, isSimple, parentKey = '', parentUrlSlug }) {
  const { endpoints } = useDynamicPageCMSDataContext();
  const sitemapDispatch = useSitemapDispatchContext();
  const { expandedKeys } = useSitemapContext();

  const currentKey = parentKey ? parentKey : index;
  const hasChildren = page.children?.length > 0;
  const isExpanded = expandedKeys[currentKey];
  const allLeafNodes = page.children?.length > 0 && page.children.every((leaf) => leaf.children.length === 0);
  const isPlaceholder = page.isPlaceholder;

  const toggleExpand = (key) => {
    sitemapDispatch({ type: 'SET_EXPANDED_KEYS', payload: { ...expandedKeys, [key]: !expandedKeys[key] } });
    // TODO, store in localStorage to persist across page refresh
  };

  const handleAddPage = (page, isCurrent) => {
    const { id, parent_category_id, is_from_sitemap, is_dynamic_page, url_slug, isCategory, isPlaceholder } = page;

    if (isCurrent) {
      window.location.href = `${endpoints.newDynamicPageUrl}?current_slug=${url_slug}`;
      return;
    }

    if ((!parent_category_id && !isCategory) || is_dynamic_page || is_from_sitemap || isPlaceholder) {
      window.location.href = `${endpoints.newDynamicPageUrl}?parent_slug=${url_slug}`;
      return;
    }

    const categoryValueUrl = `${endpoints.newCategoryValueUrl.replace(':category_id', parent_category_id)}?parent_slug=${url_slug}`;
    const fullCategoryValueUrl = !isCategory ? (id ? `${categoryValueUrl}&parent_category_value=${id}` : categoryValueUrl) : categoryValueUrl;
    window.location.href = fullCategoryValueUrl;
  };

  return (
    <div className="d-flex flex-column" style={{ paddingLeft: hasChildren ? 0 : 26 }}>
      <div className="dp-row-item">
        <div
          className="d-flex gap-3 align-items-center text-truncate h-100"
          style={{ cursor: hasChildren && 'pointer' }}
          onClick={() => toggleExpand(currentKey)}
        >
          {hasChildren && <i className={`text-muted rotate-icon fe ${isExpanded ? 'fe-minus-square expanded' : 'fe-plus-square'}`} />}
          <div className="d-flex flex-column justify-content-center text-truncate">
            <strong className="text-truncate user-select-none">{page.title}</strong>
            <span className="text-truncate text-muted user-select-none">{parentUrlSlug ? removeParentSlug(page.url_slug, parentUrlSlug) : page.url_slug}</span>
          </div>
        </div>
        {isSimple ? (
          <RowOptions {...{ page, endpoints, isPlaceholder, isSimple, handleAddPage }} />
        ) : (
          <div className="d-flex gap-4 align-items-center">
            {!isPlaceholder && <StatusBadge {...{ page }} />}
            <RowOptions {...{ page, endpoints, isPlaceholder, handleAddPage }} />
          </div>
        )}
      </div>

      {isExpanded && (
        <div style={{ paddingLeft: hasChildren ? 26 : 0 }}>
          {allLeafNodes ? (
            <TreeLeaves {...{ pages: page.children, isSimple, handleAddPage, parentUrlSlug: page.url_slug }} />
          ) : (
            hasChildren &&
            page.children.map((child, index) => (
              <TreeNode key={index} {...{ page: child, parentKey: `${currentKey}-${index}`, isSimple, parentUrlSlug: page.url_slug }} />
            ))
          )}
        </div>
      )}
    </div>
  );
}
