import { formatDateTime } from '../../../utils/DateFormatters';
import StatusBadge from './StatusBadge';

const determineType = (page) => {
  if (page.is_dynamic_page) {
    return 'Builder';
  }
  if (page.parent_category_id) {
    return 'Category';
  }
  if (page.is_from_sitemap) {
    return 'Sitemap';
  }
  return null;
};

const determineLink = (page, endpoints) => {
  const { editDynamicPageUrl, editCategoryValueUrl } = endpoints;

  if (page.is_dynamic_page) {
    return editDynamicPageUrl.replace(':id', page.id);
  }
  if (page.parent_category_id) {
    return editCategoryValueUrl.replace(':category_id', page.parent_category_id).replace(':category_name', page.parent_category_name).replace(':id', page.id);
  }
  if (page.is_from_sitemap) {
    return `/_sf/admin/content/editor?path=/${page.url_slug}`;
  }
  return null;
};

export default function RowOptions({ page, endpoints, isSimple, isPlaceholder, handleAddPage }) {
  const { currentDomain } = endpoints;
  const { is_from_sitemap: isFromSitemap } = page;

  const editLink = determineLink(page, endpoints);
  const pageType = determineType(page);
  const updatedAt = page.updated_at;
  const fullUrl = page.url_slug;
  const fullTitle = page.title;

  const pathToVisit = page.url_slug === '/' ? '' : page.url_slug;

  const handleItemClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="dp-row-option-full">
      <span className="text-muted">{pageType}</span>
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-info" />
        </button>
        <ul className="dropdown-menu" style={{ width: 330 }}>
          {isSimple && (
            <>
              <li className="d-flex align-items-center justify-content-center pb-2">
                <StatusBadge {...{ page }} />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
            </>
          )}
          <li onClick={handleItemClick}>
            <div className="dropdown-item-text d-flex flex-column gap-2">
              <strong className="mb-0 user-select-none">Title</strong>
              <p className="mb-0 user-select-auto">{fullTitle}</p>
            </div>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li onClick={handleItemClick}>
            <div className="dropdown-item-text d-flex flex-column gap-2">
              <strong className="mb-0 user-select-none">Full URL</strong>
              <p className="mb-0 user-select-auto">{fullUrl}</p>
            </div>
          </li>
          {updatedAt && (
            <>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li onClick={handleItemClick}>
                <div className="dropdown-item-text d-flex flex-column gap-2">
                  <strong className="mb-0 user-select-none">Updated at</strong>
                  <p className="mb-0">{formatDateTime(updatedAt)}</p>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          {editLink && (
            <li>
              <a className="dropdown-item" rel="noopener noreferrer" href={editLink}>
                Edit
              </a>
            </li>
          )}
          {isFromSitemap && (
            <li>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={`${currentDomain}${pathToVisit}`}>
                View
              </a>
            </li>
          )}
          {isPlaceholder && handleAddPage && (
            <li>
              <button className="dropdown-item" type="button" onClick={() => handleAddPage(page, true)}>
                Create new page for this route
              </button>
            </li>
          )}
          {handleAddPage && (
            <li>
              <button className="dropdown-item" type="button" onClick={() => handleAddPage(page)}>
                Add new page under this route
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
