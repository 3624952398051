import { useState, useContext, useEffect } from 'react';
import { QuestionsContext } from '../contexts/QuestionsContext';

import { Entity } from '@quake-saas/quake-sdk';

import { EntityValue } from '@quake-saas/inputs'

export default function AnswerList({ answers }){
  const { questions } = useContext(QuestionsContext);

  const foo = Entity.Date.dataType

  return(
    <div class="list-group list-group-flush my-n3">
      { answers.map((answer) => {
        const question = questions.find((question) => question.entity.id == answer.entityId)
        return(
          <div class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="mb-2 text-white">
                  {question.text.default}
                </h4>
                <small class="text-white">
                <EntityValue key={answer.id} entityId={answer.entityId} value={answer.value} readOnly={true}/>
                </small>
              </div>
            </div>
          </div>
        )
      })}      
    </div>
  )
}
