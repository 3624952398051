import { useDynamicPageCMSDataContext } from '../../contexts';

import StatusBadge from '../_site_tree/StatusBadge';
import RowOptions from '../_site_tree/RowOptions';

export default function ListItem({ page, isSimple, style }) {
  const { endpoints } = useDynamicPageCMSDataContext();

  const handleAddPage = (page) => {
    const { id, parent_category_id, is_from_sitemap, is_dynamic_page, url_slug, isCategory, isPlaceholder } = page;

    if (!parent_category_id && !isCategory && (is_dynamic_page || is_from_sitemap || isPlaceholder)) {
      window.location.href = `${endpoints.newDynamicPageUrl}?parent_slug=${url_slug}`;
      return;
    }

    const categoryValueUrl = `${endpoints.newCategoryValueUrl.replace(':category_id', parent_category_id)}?parent_slug=${url_slug}`;
    const fullCategoryValueUrl = !isCategory ? (id ? `${categoryValueUrl}&parent_category_value=${id}` : categoryValueUrl) : categoryValueUrl;
    window.location.href = fullCategoryValueUrl;
  };

  return (
    <div className="dp-row-item" style={style}>
      <div className="d-flex gap-3 align-items-start text-truncate">
        <div className="d-flex flex-column justify-content-center text-truncate">
          <strong className="text-truncate user-select-none">{page.title}</strong>
          <span className="text-truncate text-muted user-select-none">{page.url_slug}</span>
        </div>
      </div>
      {isSimple ? (
        <RowOptions {...{ page, endpoints, isSimple, handleAddPage }} />
      ) : (
        <div className="d-flex gap-4 align-items-center">
          <StatusBadge {...{ page }} />
          <RowOptions {...{ page, endpoints, handleAddPage }} />
        </div>
      )}
    </div>
  );
}
