import React, { useContext, useEffect, useState } from 'react';

import { Question } from "@quake-saas/quake-sdk";
import { useListLoader } from "@quake-saas/util";

import { QuestionsContext } from "../contexts/QuestionsContext";

export default function SurveyLoader({ children, companyId } = {}) {
  const { requestState, itemList } = useListLoader({ type: Question, filters: { company: companyId }, canQuery: !!companyId });

  return (
    <QuestionsContext.Provider value={{ requestState, questions: itemList}}>
      {children}
    </QuestionsContext.Provider>
  )
}
