import React, { useContext, useEffect, useState } from 'react';

import { Survey, FlowInstance } from "@quake-saas/quake-sdk";
import { useListLoader } from "@quake-saas/util";
import { dequal } from 'dequal';

import { SurveyListContext } from "../contexts/SurveyListContext";

export default function AllSurveysLoader({ children, companyId, userTypeEntityId, userTypeEntityValueId, canQuery = false } = {}) {
  const [surveyScores, setSurveyScores] = useState({})
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const { requestState, itemList } = useListLoader({ type: Survey, opts: { sort: 'scheduleStartAt' }, filters: { company: [companyId], state: 'started', entity: { [userTypeEntityId]: userTypeEntityValueId} }, canQuery });

  useEffect(()=> {
    if(itemList.length == 0) return;

    itemList.map((survey) => 
      FlowInstance.scores({ filters: { context: survey.id } })
        .then(scores => 
          setSurveyScores(current =>  {
            const proposed = { ...current, [survey.id]: scores }
            return(dequal(current, proposed) ? current : proposed) 
          })
        )
    )
  }, [requestState, itemList])

  useEffect(() => {
    if(selectedSurvey) return
    if(itemList.length == 0) return;

    setSelectedSurvey(itemList[0])
  }, [requestState, itemList])


  return (
    <SurveyListContext.Provider value={{ requestState, surveyList: itemList, scores: surveyScores, selectedSurvey, setSelectedSurvey }}>
      {children}
    </SurveyListContext.Provider>
  )
}