import { useState, useContext, useEffect } from 'react';
import { Contact, Answer, FlowInstance } from "@quake-saas/quake-sdk";

import { EntityGroupsContext } from "../contexts/EntityGroupsContext";

import { badgeFromScore } from "../utils/ScoreColours";
import { meanToScore } from "../utils/ScoreCalculations"

import AnswerList from './AnswerList';


export default function ResponseCard({ flowInstance }){
  const { id: flowInstanceId, contactId, surveyId } = flowInstance;
  const entityGroupsContext = useContext(EntityGroupsContext);

  const { requestState: contactRequestState, item: contact } = useItemLoader({ type: Contact, id: contactId, canQuery: true });
  const { requestState: answersRequestState, itemList: answers } = useListLoader({ type: Answer, filters: { flowInstance: flowInstanceId }, canQuery: true, listFunc: FlowInstance.answers.bind(FlowInstance) });

  const [flowInstanceScores, setFlowInstanceScores] = useState({})
  const [flowInstanceEntityGroups, setFlowInstanceEntityGroups] = useState([])

  useEffect(()=> {
    if(!contact?.id) return;

    FlowInstance.scores({ filters: { context: surveyId, contact: contact.id } })
      .then(scores => {
        setFlowInstanceScores({ ...scores })
      })
  }, [contactRequestState, contact])

  useEffect(() => {
    if(!flowInstanceScores?.values) return;

    setFlowInstanceEntityGroups(Object.keys(flowInstanceScores.values.entityGroups))
  }, [flowInstanceScores])

  return(
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div className="row">
          <div className="col">
            { contactRequestState == 'loaded' &&
              <span>{contact.firstName} {contact.lastName}</span>
            }
          </div>
          <div className="col-auto justify-content-between">
            { flowInstanceEntityGroups.slice(0, 4).map((entityGroupId) =>
              {
                const score = meanToScore(flowInstanceScores.values.entityGroups[entityGroupId].mean)

                return(
                  <span key={entityGroupId} className="h2 mb-0 me-1">
                    <span className={`badge ${badgeFromScore(score)}`}>
                      {score.toFixed(1)}
                    </span>
                  </span>
                )
              }
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="col">
          {/* <pre>{JSON.stringify(questions, undefined, 2)}</pre> */}
          {/* <pre>{JSON.stringify(answers, undefined, 2)}</pre> */}
          <AnswerList answers={answers} />
        </div>
        {/* <div className="col-auto">
          <button className="btn btn-outline-info">View all feedback</button>
        </div> */}
      </div>
    </div>
  )
}