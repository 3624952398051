import { removeParentSlug } from '../../../utils/UrlFormatters';
import { useDynamicPageCMSDataContext } from '../../contexts';

import RowOptions from './RowOptions';
import StatusBadge from './StatusBadge';

export default function TreeLeaf({ page, isSimple, handleAddPage, parentUrlSlug }) {
  const { endpoints } = useDynamicPageCMSDataContext();

  return (
    <div className="dp-row-item">
      <div className="d-flex flex-column justify-content-center text-truncate">
        <strong className="text-truncate user-select-none">{page.title}</strong>
        <span className="text-truncate text-muted user-select-none">{parentUrlSlug ? removeParentSlug(page.url_slug, parentUrlSlug) : page.url_slug}</span>
      </div>
      {isSimple ? (
        <RowOptions {...{ page, endpoints, isSimple, handleAddPage }} />
      ) : (
        <div className="d-flex gap-4 align-items-center">
          <StatusBadge {...{ page }} />
          <RowOptions {...{ page, endpoints, handleAddPage }} />
        </div>
      )}
    </div>
  );
}
