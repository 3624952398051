import { useSitemapContext } from '../contexts';

import TreeNode from './_site_tree/TreeNode';

export default function SiteTree({ isSimple }) {
  const state = useSitemapContext();

  if (!state.pages.length) {
    return <div className="d-flex align-items-center justify-content-center text-muted pt-5">No pages found.</div>;
  }

  return (
    <div className="dp-site-tree-list overflow">
      {state.pages.map((page, index) => {
        return <TreeNode key={index} {...{ page, index, isSimple }} />;
      })}
    </div>
  );
}
