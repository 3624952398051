import { useSitemapContext } from '../contexts';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import ListItem from './_site_list/ListItem';

export default function SiteList({ isSimple }) {
  const state = useSitemapContext();

  if (!state.pages.length) {
    return <div className="d-flex align-items-center justify-content-center text-muted pt-5">No pages found.</div>;
  }

  return (
    <div className="dp-site-tree-list">
      <AutoSizer>
        {({ height, width }) => (
          <List className="List" height={height} width={width} itemData={state.pages} itemCount={state.pages.length} itemSize={55}>
            {({ data, index, style }) => {
              return <ListItem page={data[index]} isSimple={isSimple} style={style} />;
            }}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}
