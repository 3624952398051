import { useState, useContext, useEffect } from 'react';
import { HTTPClient, FlowInstance } from "@quake-saas/quake-sdk";

import { SurveyListContext } from './contexts/SurveyListContext';

import { badgeFromScore, surveyStateBadge } from "./utils/ScoreColours";
import { meanToScore } from "./utils/ScoreCalculations"

export default function SurveyNav(){
  const { requestState, surveyList, scores, selectedSurvey, setSelectedSurvey } = useContext(SurveyListContext);

  const scoreArrow = function(score, lastScore){
    if(score > lastScore){
      return 'fe-arrow-up-right text-success';
    } else if(score < lastScore){
      return 'fe-arrow-down-right text-danger';
    } else {
      return 'fe-arrow-right text-muted';
    }
  }

  return(
    surveyList &&
      <>
        <ul className='nav nav-tabs mb-4'>
          { surveyList.map((survey, index) => {
              const score = meanToScore(scores[survey.id]?.values?.summary?.mean)
              const lastScore = index > 0 ? meanToScore(scores[surveyList[index - 1].id]?.values?.summary?.mean) : null
              return(
                <li className='nav-item' key={survey.id}>
                  <button className={`nav-link pb-2 ${survey?.id == selectedSurvey?.id ? 'active' : ''}`} onClick={()=> setSelectedSurvey(survey)}>
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>{survey.name.slice(0, 15)}</span>
                        {/* <span className='me-2'>{survey.id}</span> */}
                        <span className={`badge ${badgeFromScore(score)}`}>{score == '-' ? score : score.toFixed(1)}</span>
                        { lastScore && score != '-' &&
                          <sup className={`fe ${scoreArrow(score, lastScore)}`} />
                        }
                      </div>
                      <div className='d-flex align-items-start'>
                        <span className={`fs-6 text-white`}>{survey.state}</span>
                      </div>
                  </button>
                </li>
              )
          })}
        </ul>
        {/* <pre>{JSON.stringify(scores, undefined, 2)}</pre> */}
        {/* <pre>{selectedSurvey?.id}</pre> */}
      </>
  )
}