export const toUrlSlug = (input) => {
  return input
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading and trailing whitespace
    .replace(/[^a-z0-9\s-_]/g, '') // Remove non-alphanumeric characters except hyphens and underscores
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/^-+|-+$/g, ''); // Trim leading and trailing hyphens
};

export const transformUrlSlugToTitle = (urlSlug) => {
  const lastSegment = urlSlug.split('/').filter(Boolean).pop(); // Get the last segment separated by "/"
  return lastSegment
    .split('-') // Split by hyphens
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join with spaces
};

export const removeParentSlug = (urlSlug, parentSlug) => {
  return urlSlug.replace(new RegExp(`^${parentSlug}(\\/|$)|^\\/|\\/$`, 'g'), ''); // Remove parent slug and all leading/trailing slashes
};

export const getQueryParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};
